import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const author = "Campeones financieros"

const routes = [
  {
    path: '/',
    name: 'generator',
    meta: {title: 'Admin -' + author},
    component: ()  => import('../views/GenerateCodes')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

//define title page
router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})

export default router
